var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"fullscreen":_vm.isMobile,"max-width":"560px","transition":_vm.isMobile ? 'dialog-bottom-transition' : 'dialog-transition',"persistent":""},model:{value:(_vm.isSuggestionVisible),callback:function ($$v) {_vm.isSuggestionVisible=$$v},expression:"isSuggestionVisible"}},[_c('v-card',{staticClass:"card pb-4 px-4"},[_c('v-toolbar',{staticClass:"toolbar",attrs:{"flat":"","color":"white"}},[_c('v-btn',{staticClass:"btn-close text-sm-left",attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.closeModal()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_vm._v(" "),(_vm.autoplayContent && _vm.isActiveAutoplay)?_c('div',{staticClass:"card-text"},[(_vm.autoplayContent.metadatas.thumbnail)?_c('v-img',{staticClass:"img mr-3",attrs:{"src":_vm.autoplayContent.metadatas.thumbnail.value,"aspect-ratio":"1"}}):_c('v-img',{staticClass:"img mr-3",attrs:{"src":_vm.autoplayContent.metadatas.cards[0].value,"aspect-ratio":"1"}}),_vm._v(" "),_c('div',[(!_vm.isCanceled)?_c('v-card-text',{staticClass:"card-pre-title font-italic pa-0"},[_vm._v("\n          "+_vm._s(_vm.$t('cp_wsuggestionmodal_next_in'))+" "+_vm._s(_vm.timeLeft)+"\n        ")]):_vm._e(),_vm._v(" "),_c('v-card-text',{staticClass:"card-title pa-0"},[_vm._v("\n          "+_vm._s(_vm.autoplayContent.name)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"subtitle mt-2"},[_c('v-card-text',{staticClass:"py-1 pl-0 caption text-uppercase text-no-wrap"},[_vm._v("\n            "+_vm._s(_vm.formatDate(_vm.autoplayContent.publishedAt))+"\n          ")]),_vm._v(" "),(_vm.autoplayContent.metadatas.audios)?_c('v-card-text',{staticClass:"py-1 px-2 caption text-uppercase text-no-wrap grey lighten-3"},[_vm._v("\n            "+_vm._s(_vm.getHumanDuration(
                _vm.autoplayContent.metadatas.audios.reduce(
                  (sum, audio) => sum + audio.metadatas.duration,
                  0
                )
              ))+"\n          ")]):_c('v-card-text',{staticClass:"py-1 px-2 caption text-uppercase text-no-wrap grey lighten-3"},[_vm._v("\n            "+_vm._s(_vm.getHumanDuration(
                _vm.autoplayContent.metadatas.videos.reduce(
                  (sum, video) => sum + video.metadatas.duration,
                  0
                )
              ))+"\n          ")])],1)],1)],1):_vm._e(),_vm._v(" "),(_vm.autoplayContent && _vm.isActiveAutoplay)?_c('v-card-actions',{staticClass:"card-action px-0 mt-3 mb-2"},[_c('v-btn',{attrs:{"color":"grey lighten-2","disabled":_vm.isCanceled,"block":"","depressed":""},on:{"click":function($event){return _vm.closeModal('cancel')}}},[_vm._v("\n        "+_vm._s(_vm.$t('cp_wsuggestionmodal_cancel'))+"\n      ")]),_vm._v(" "),_c('v-btn',{staticClass:"progress-btn",attrs:{"color":"primary lighten-4","block":"","depressed":""},on:{"click":function($event){return _vm.launchContent(_vm.autoplayContent)}}},[_c('span',[_vm._v(_vm._s(_vm.$t('cp_wsuggestionmodal_listen')))]),_vm._v(" "),_c('v-progress-linear',{attrs:{"value":_vm.progress,"color":"primary","height":"50"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.isActiveSuggested)?_c('w-suggestion-card',{attrs:{"catch-phrase":_vm.$t('cp_wsuggestionmodal_theme'),"contents":_vm.contents,"is-universe":_vm.isUniverse,"is-other":false,"is-active-autoplay":_vm.isActiveAutoplay},on:{"goTo":function($event){_vm.isUniverse ? _vm.goTo(_vm.collection.id) : _vm.goTo()},"launchContent":function($event){return _vm.launchContent($event)}}}):_vm._e(),_vm._v(" "),(_vm.isActiveSuggested && _vm.isUniverse)?_c('w-suggestion-card',{attrs:{"catch-phrase":_vm.$t('cp_wsuggestionmodal_other'),"contents":_vm.otherContents,"is-universe":_vm.isUniverse,"is-other":true},on:{"launchContent":function($event){return _vm.launchContent($event)},"goTo":function($event){return _vm.goTo()}}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }